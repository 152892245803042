@use 'styles/config' as *;

.events {
  @include segment;

  &__container {
    @include container;
  }

  &__header {
    margin-bottom: responsive(3.7rem, 6rem);

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__tagline {
    @include taglineWithLine;
    margin-bottom: 4em;
  }

  &__title {
    @include h2(3rem, 4rem);
    @include headingLine;
  }

  &__list {
    display: grid;
    column-gap: 1.6rem;
    row-gap: 2.4rem;
    grid-template-columns: minmax(0, 1fr);

    @media (min-width: $min-600) {
      grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    }

    &:not(:last-child) {
      margin-bottom: responsive(5rem, 8rem);
    }
  }
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $color-border;

  :global(body.brand) & {
    border-color: #000;
  }

  :global(body.dark) & {
    color: #fff;
  }

  &__header {
    padding: 2.4rem 2.4rem 4rem;
  }

  &__date {
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    margin-bottom: 1.1em;
    font-size: 1.4rem;
    line-height: 1.4;
    font-weight: 600;
  }

  &__title {
    @include h4;
  }

  &__footer {
    height: 6.8rem;
    display: flex;
    border-top: 1px solid $color-border;

    :global(body.brand) & {
      border-color: #000;
    }
  }

  &__action {
    @include reset-button;
    @include reset-anchor;
    flex: 1 1 auto;
    display: flex;
    padding: 0 2.4rem;
    margin-right: 6.8rem;
    align-items: center;
    justify-content: flex-start;

    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4;
    color: $color-copy;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    transition-property: background-color, color;
    transition-duration: 200ms;

    cursor: pointer;

    :global(body.dark) & {
      color: #fff;
    }

    :global(body.brand) & {
      color: #000;
    }

    &:hover {
      color: #fff;
      background-color: brand();

      :global(body.brand) & {
        color: #000;
        background: #fff;
      }
    }

    svg {
      flex: 0 0 auto;
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 1.6rem;
      margin-top: -0.2rem;
    }
  }

  &__button {
    position: absolute;
    right: 0;
    bottom: 0;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.8rem;
    height: 6.7rem;
    color: brand();
    border-left: 1px solid $color-border;

    transition-property: background-color, color;
    transition-duration: 200ms;

    :global(body.brand) & {
      color: #fff;
      border-color: #000;
    }

    svg {
      display: block;
      width: 1.1rem;
      height: 1.8rem;
    }
  }

  &__link {
    @include reset-anchor;
  }

  &__link:hover &__button {
    color: #fff;
    background-color: brand();

    :global(body.brand) & {
      color: brand();
      background-color: #fff;
    }
  }
}
