@use 'styles/config' as *;
@use "sass:math";

.offices {
  :global(body.dark) & {
    color: #fff;
  }

  &__segment {
    @include segment;
  }

  &__container {
    @include container;
  }

  &__row {
    @include grid-row;
  }

  &__text {
    @include grid-col;

    margin-bottom: 10rem;

    @media (min-width: $min-960) {
      @include grid-col(8);
      @include grid-offset-left(1);
    }

    @media (min-width: $min-1280) {
      @include grid-col(7);
      @include grid-offset-left(1);
    }
    @media (min-width: $min-1440) {
      @include grid-col(6);
      @include grid-offset-left(1);
    }
  }

  &__list {
    @include supports-grid() {
      display: grid;
      width: 100%;
      grid-row-gap: $gutter * 2;

      .two-columns &,
      .two-columns-featured & {
        @media (min-width: $min-720) {
          grid-column-gap: $gutter * 1.5;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (min-width: $min-1080) {
          grid-column-gap: $gutter * 2.5;
        }
      }
    }

    @include no-supports-grid() {
      @include grid-row();

      & > * {
        @include grid-col(6);
        margin-bottom: $gutter * 2;
      }
    }
  }
}

.item {
  height: 100%;
  // max-width: 55rem;
  display: flex;
  align-items: stretch;
  padding-bottom: $gutter * 2;

  border-bottom: 1px solid $color-border;

  &__featured {
    @media (min-width: $min-720) {
      grid-column: span 2;
    }
  }

  &__layout {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
  }

  &__content {
    flex: 1 1 auto;
    margin-bottom: $gutter;
  }

  &__title {
    @include h5(1.8rem, 2rem);
    font-weight: 600;
    line-height: math.div(28, 20);
    margin-bottom: 1.2em;

    @media (min-width: $min-960) {
      margin-bottom: 1.5em;
    }
  }

  &__copy {
    @include render-copy(1.5rem, 1.8rem);
    max-width: 42rem;

    .featured & {
      max-width: 82rem;
    }

    p {
      b,
      strong {
        font-weight: 600;
      }
    }
  }

  &__address {
    @include render-copy(1.2rem, 1.5rem);

    p {
      margin: 0;
      letter-spacing: 0.025em;
      color: $color-font;

      :global(body.dark) & {
        color: #fff;
      }
    }
  }

  &__address + &__contact {
    margin-top: $gutter;
  }

  &__contact {
    &List {
      @include reset-list();
    }

    &Item {
      display: flex;
      align-items: center;
      font-size: responsive(1.2rem, 1.5rem);
      letter-spacing: 0.025em;

      &:not(:first-child) {
        margin-top: 0.5rem;
      }
    }

    &Label {
      display: block;
    }

    &Link {
      @include anchor();
      text-decoration: none;
      margin-left: 1rem;
    }
  }
}
