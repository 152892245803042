@use 'styles/config' as *;

.form {
  &__segment {
    .full & {
      @include segment;
    }

    .split & {
      @include split-segment;
    }
  }

  &__container {
    .full & {
      @include container;
    }
  }

  &__row {
    @include grid-row;
  }

  &__col {
    @include grid-col;
  }

  &__header {
    margin-bottom: 6rem;

    @media (min-width: $min-720) {
      margin-bottom: 8rem;
    }
  }

  &__title {
    @include h2();
    max-width: 64rem;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__layout {
    @include grid-col();

    .full & {
      @media (min-width: $min-720) {
        @include grid-col(8);
        @include grid-offset-left(2);
      }

      @media (min-width: $min-960) {
        @include grid-col(6);
        @include grid-offset-left(3);
      }
    }
  }

  &__copy {
    @include render-copy();
    margin-top: 3rem;

    @media (min-width: $min-720) {
      margin-top: 4rem;
    }

    :global(body.brand) & {
      p {
        color: $color-font;
      }
    }
  }
}
