@use 'styles/config' as *;

.grid {
  &__segment {
    .full & {
      @include segment();
    }

    .split & {
      @include split-segment();
    }

    .article & {
      @include article-segment();
    }
  }

  &__container {
    .full &,
    .article & {
      @include container();
    }
  }

  &__header {
    margin-bottom: 8rem;
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h2;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__row {
    @include grid-row();
  }

  &__column {
    @include grid-col();

    @media (min-width: $min-960) {
      @include grid-col(10);
      @include grid-offset-left(1);
    }

    @media (min-width: $min-1280) {
      @include grid-col(8);
      @include grid-offset-left(2);
    }

    .large & {
      @include grid-col();
      @include grid-offset-left(0);
    }
  }

  &__layout {
    @include supports-grid() {
      display: grid;
      grid-auto-flow: row dense;
      grid-gap: 1.5rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-auto-rows: 1fr;
      margin-left: -$gutter;
      margin-right: -$gutter;

      @media (min-width: $min-720) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-left: 0;
        margin-right: 0;
      }

      .large & {
        @media (min-width: $min-960) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @media (min-width: $min-1280) {
          grid-template-columns: repeat(5, minmax(0, 1fr));
        }
      }
    }

    @include no-supports-grid() {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}

.item {
  position: relative;

  & > div:last-child {
    position: absolute 0;
  }

  @include supports-grid() {
    grid-row: span 4;

    &:first-child {
      grid-column: 1 / 2;

      @media (min-width: $min-720) {
        grid-row: 2 / 6;
      }
    }

    &:nth-child(2) {
      grid-row: 2 / 6;
      grid-column: 2 / 3;

      @media (min-width: $min-720) {
        grid-row: 3 / 7;
      }
    }

    .large & {
      &:nth-child(4) {
        @media (min-width: $min-960) {
          grid-row: 2 / 6;
          grid-column: 4 / 5;
        }
      }

      &:nth-child(5) {
        @media (min-width: $min-1280) {
          grid-row: 3 / 7;
          grid-column: 5 / 6;
        }
      }
    }

    &.tall {
      grid-row: span 8;

      &:first-child {
        @media (min-width: $min-720) {
          grid-row: 2 / 10;
        }
      }

      &:nth-child(2) {
        grid-row: 2 / 10;

        @media (min-width: $min-720) {
          grid-row: 3 / 11;
        }
      }

      .large & {
        &:nth-child(4) {
          @media (min-width: $min-960) {
            grid-row: 2 / 10;
          }
        }

        &:nth-child(5) {
          @media (min-width: $min-1280) {
            grid-row: 3 / 11;
          }
        }
      }
    }
  }

  @include no-supports-grid() {
    flex: 0 1 auto;
    overflow: hidden;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;

    .grid:not(.large) & {
      flex-basis: calc(33% - 1rem);

      &:nth-child(3n-2) {
        transform: translateY(6rem);
      }

      &:nth-child(3n-1) {
        transform: translateY(12rem);
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .large & {
      flex-basis: calc(20% - 1.2rem);

      &:nth-child(5n-4) {
        transform: translateY(6rem);
      }

      &:nth-child(5n-3) {
        transform: translateY(12rem);
      }

      &:nth-child(5n-1) {
        transform: translateY(6rem);
      }

      &:nth-child(5n) {
        margin-right: 0;
        transform: translateY(12rem);
      }
    }
  }

  &__image {
    &::after {
      content: '';
      position: absolute 0;
      pointer-events: none;
      box-shadow: inset 0 0 3rem 0 rgba(#000, 0.04);
    }
  }

  &__aspect {
    width: 100%;
    height: 0;
    padding-bottom: 92%;

    @include supports-grid() {
      .tall & {
        padding-bottom: 184%;
      }
    }
  }

  &__img {
    position: absolute 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
