@use 'styles/config' as *;

.video {
  &.split {
    @include split-segment();
  }

  &__segment {
    @include segment();
  }

  &__articleSegment {
    @include article-segment();
  }

  &__container {
    @include container();
  }

  &__title {
    @include h2();
    margin-bottom: 1em;

    @media (min-width: $min-720) {
      margin-bottom: 2em;
    }

    .split & {
      @media (min-width: $min-1080) {
        margin-bottom: 1em;
      }
    }
  }

  &__wrap {
    @media (max-width: $max-720) {
      margin-left: -$gutter;
      margin-right: -$gutter;
    }
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();

    .article &,
    .full & {
      @media (min-width: $min-1280) {
        @include grid-col(10);
        @include grid-offset-left(1);
      }
    }
  }

  &__aspect {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__embed {
    @include fit();

    iframe {
      @include fit();
      width: 100%;
      height: 100%;
    }
  }
}
