@use 'styles/config' as *;

.videos {
  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__layout {
    @include supports-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas:
        'header'
        'primary';
      row-gap: 3rem;
      column-gap: 3rem;

      .hasList & {
        grid-template-areas:
          'header'
          'primary'
          'list';
      }

      @media (min-width: $min-840) {
        row-gap: 0;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-template-areas: 'header header header header header . primary primary primary primary primary primary';

        .hasList & {
          grid-template-areas:
            'header header header header header . primary primary primary primary primary primary'
            'list list list list list list list list list list list list ';
          row-gap: 8rem;
        }
      }

      @media (min-width: $min-1200) {
        grid-template-areas: 'header header header header . primary primary primary primary primary primary primary';

        .hasList & {
          grid-template-areas:
            'header header header header . primary primary primary primary primary primary primary'
            'list list list list list list list list list list list list ';
        }
      }
    }

    @include no-supports-grid {
      @include grid-row;
    }
  }

  &__header {
    grid-area: header;
    align-self: center;
    // padding-top: 5rem;

    @include no-supports-grid {
      @include grid-col(5);
      margin-bottom: 3rem;
    }
  }

  &__title {
    @include h2;
    position: relative;
    max-width: 64rem;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__text {
    @include render-copy;
    margin-top: 1.5em;
  }

  &__primary {
    grid-area: primary;

    @include no-supports-grid {
      @include grid-col(6);
      @include grid-offset-left(1);
      margin-bottom: 3rem;
    }
  }

  &__list {
    @include supports-grid {
      grid-area: list;
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 3rem;
      column-gap: 3rem;

      @media (min-width: $min-480) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media (min-width: $min-840) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        row-gap: 6rem;
      }

      @media (min-width: $min-1200) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

    @include no-supports-grid {
      @include grid-row;
      margin-bottom: -3rem;

      > * {
        @include grid-col(3);
        margin-bottom: 3rem;
      }
    }
  }
}
