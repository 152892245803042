@use 'styles/config' as *;
@use "sass:math";

.people {
  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__title {
    @include h2();
    margin-bottom: 2em;
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();
  }

  &__header {
    @include grid-col;
    margin-bottom: $gutter;

    @media (min-width: $min-720) {
      @include grid-col(8);
      @include grid-offset-right(4);
    }

    @media (min-width: $min-960) {
      @include grid-col(6);
      @include grid-offset-right(0);
    }

    @media (min-width: $min-1280) {
      @include grid-col(5);
      @include grid-offset-right(1);
    }
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h2;
    @include headingLine;
    // margin: 0 0 2rem;

    @media (min-width: $min-720) {
      // margin: 0 0 4rem;
    }

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__text {
    @include copy;
    @include grid-col;
    @include dropCap();

    color: #757575;

    @media (min-width: $min-960) {
      @include grid-col(percentage(math.div(4, 5)));
      @include grid-offset-left(percentage(math.div(1, 5)));
    }

    :global(body.brand) & {
      color: $color-font;
    }
  }

  &__list {
    @include grid-row(rtl);

    @media (min-width: $min-960) {
      @include grid-row(ltr);
    }
  }
}

.item {
  @include grid-col;
  position: relative;
  margin-bottom: 8rem;

  &:last-child {
    margin-bottom: 4rem;
  }

  @media (min-width: $min-720) {
    @include grid-col(5);
    @include grid-offset-right(1);
    margin-top: 8rem;
    margin-bottom: 0;

    &:nth-child(2n + 1) {
      transform: translateY(20rem);
    }
  }

  @media (min-width: $min-960) {
    @include grid-col(5);
    @include grid-offset-left(1);
    margin-right: auto;
    margin-top: 8rem;

    &:nth-child(2n + 1) {
      margin-top: 0;
      transform: translateY(8rem);
    }

    &:nth-child(2n) {
      margin-top: 22rem;
    }

    &:nth-child(2) {
      margin-top: 8rem;
      align-self: flex-end;
    }
  }

  @media (min-width: $min-1280) {
    @include grid-col(4);
    @include grid-offset-left(1);
    @include grid-offset-right(1);
  }

  &__wrap {
    position: relative;
    max-width: 40rem;
    margin: 0 auto;
    padding-right: 4rem;

    @media (min-width: $min-600) {
      padding-right: 0;
    }
  }

  &__image {
    position: relative;
    margin: auto;
    overflow: hidden;
    background: #f7f7f7;

    &::after {
      content: '';
      position: absolute 0;
      background: linear-gradient(
        180deg,
        rgba(#000, 0) 0%,
        rgba(#000, 0) 55%,
        rgba(#000, 0.9) 100%
      );
    }
  }

  &__aspect {
    width: 100%;
    height: 0;
    padding-bottom: 130%;
  }

  &__img {
    position: absolute 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 0 5rem 3.5rem 3rem;

    color: #fff;

    @media (min-width: $min-720) {
      padding: 0 6rem 4.5rem 3.8rem;
    }
  }

  &__name {
    @include h4;
    margin-bottom: 0.15em;

    &::before {
      content: '';
      margin-bottom: 1.3rem;
      display: block;
      width: 27px;
      height: 2px;
      @include brandColor(background);
    }
  }

  &__position {
    @include reset-paragraph;
    font-size: responsive(1.4rem, 1.8rem);
    font-style: italic;
    font-weight: 400;
  }

  &__button {
    position: absolute;
    right: 0%;
    bottom: 0%;

    > * {
      display: flex;
      transform: translate(50%, 50%);
    }
  }
}

.peopleInfo {
  @include unordered-list;

  &__item {
    @include copy(1.4rem, 1.8rem);

    &:not(:first-child) {
      margin-top: 1em;
    }

    &:last-child {
      margin-bottom: 0;
    }

    li:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &__link {
    @include reset-anchor;
    color: brandDark();

    &:hover {
      color: brand();
    }
  }
}
