@use 'styles/config' as *;
@use "sass:math";

.hero {
  @include segment-top();
  @include segment-bottom(8rem, 0);

  &.hasNav {
    @include nav-offset-padding();
  }

  position: relative;

  &.full {
    @media (min-width: $min-960) {
      display: flex;
      min-height: 100vh;
    }

    @include segment-top();
    @include segment-bottom();
    // margin-bottom: $segment-padding-mobile;

    // @media (min-width: $min-720) {
    //   margin-bottom: $segment-padding;
    // }

    &:first-child {
      // margin-top: -$layout-padding-top-mobile;

      @media (min-width: $min-960) {
        margin-top: -$layout-padding-top;
      }
    }

    &:last-child {
      margin-bottom: 0;

      @media (min-width: $min-720) {
        margin-bottom: 0;
      }
    }

    &.hasNav {
      @include nav-offset-margin();
    }
  }

  &__container {
    @include container();

    display: flex;
    align-items: center;

    min-height: 100%;
    width: 100%;
  }

  &__content {
    width: 100%;
  }

  &__row {
    @include grid-row;
  }

  &__column {
    @include grid-col();

    @media (min-width: $min-960) {
      @include grid-col(9);
    }

    @media (min-width: $min-1280) {
      @include grid-col(8);
    }

    @media (min-width: $min-1440) {
      @include grid-col(7);
    }

    .full & {
      @include grid-col();

      @media (min-width: $min-960) {
        @include grid-col(5);
      }
    }
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h1;

    margin: 0;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__text {
    @include grid-col();

    @media (min-width: $min-960) {
      @include grid-col(6);
      @include grid-offset-left(1);
    }

    @media (min-width: $min-1280) {
      @include grid-col(5);
      @include grid-offset-left(1);
    }

    @media (min-width: $min-1440) {
      @include grid-col(4);
      @include grid-offset-left(1);
    }

    margin-top: 4.5rem;

    font-size: 1.8rem;
    line-height: math.div(30, 18);
    color: #757575;

    @include dropCap();

    :global(body.brand) & {
      color: $color-font;
    }

    :global(.dark-theme) & {
      color: #fff;
    }

    .full & {
      @include grid-col();
      @include grid-offset-left(0);

      @media (min-width: $min-960) {
        @include grid-col(5);
        @include grid-offset-left(0);
      }

      @media (min-width: $min-1280) {
        @include grid-col(4);
        @include grid-offset-left(1);
      }
    }

    a {
      @include anchor();
    }
  }

  &__button {
    @include grid-col();
    margin-top: 6rem;
    margin-bottom: -4rem;

    @media (min-width: $min-960) {
      @include grid-col(5);
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center center;

    @media (min-width: $min-960) {
      position: absolute 0;
    }
  }

  &__video {
    z-index: 1;
  }

  &__background {
    position: relative;
    display: flex;
    margin-top: 6rem;
    background: #eee;

    .full & {
      height: min(100vh, 80rem);
      overflow: hidden;
      background-position: center center;
    }

    @media (min-width: $min-960) {
      position: absolute 0;
      margin-top: 13rem;
      margin-bottom: 13rem;
      left: 50%;

      .full & {
        height: auto;
        max-height: 120vh;
        max-width: 60rem;
      }
    }
  }

  &__arrow {
    position: absolute;
    left: $gutter * 1.5;
    bottom: $gutter * 1.5;
    z-index: 3;
  }

  &__play {
    z-index: 2;
  }
}
