@use 'styles/config' as *;

.textbox {
  position: relative;
  margin-bottom: 3.5rem;

  &__label {
    @include formLabel;
  }

  &__input {
    @include formInput();
  }

  textarea {
    height: 10rem;
    padding: 1rem 0;

    @media (min-width: $min-720) {
      height: 15rem;
    }
  }

  &__message {
    @include copy(1rem, 1.1rem);
    position: absolute;
    top: 0;
    right: 0;
  }
}
