@use 'styles/config' as *;

.list {
  :global(body.dark) & {
    color: #fff;
  }

  &__segment {
    @include segment;
  }

  &__container {
    @include container();
  }

  &__header {
    max-width: 60rem;
    margin-bottom: 8rem;
  }

  &__title {
    @include h2;
  }

  &__text {
    @include copy;
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();
  }

  &__list {
    @include grid-row();
    margin-bottom: -8rem;
  }

  &__splitSegment {
    @include split-segment;
  }
}

.item {
  @include grid-col();
  margin-bottom: 8rem;

  .full & {
    @media (min-width: $min-720) {
      @include grid-col(6);
    }

    @media (min-width: $min-1080) {
      @include grid-col(6);
    }
  }

  .split & {
    @media (min-width: $min-720) {
      @include grid-col(6);
    }

    @media (min-width: $min-1080) {
      @include grid-col();
    }
  }

  &__content {
    margin: 0 auto;

    @media (min-width: $min-720) {
      padding-right: $gutter;
    }
  }

  &__content {
    margin-top: -0.75em;
  }

  &__title {
    @include h3;
  }

  &__text {
    @include render-copy;

    // a {
    //   @include anchor;
    // }

    // p {
    //   @include copy;
    // }

    // :global(body.brand) & {
    //   color: $color-font;
    // }

    :global(.note) {
      font-size: 1.2rem;
    }
  }

  &__title + &__text {
    margin-top: 2rem;
  }
}
