@use 'styles/config' as *;

.block {
  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();
  }

  &__layout {
    @include grid-row();
  }

  &__row {
    @include grid-row();
  }

  &__imageCol {
    @include grid-col();

    @media (min-width: $min-720) {
      @include grid-col(6);
      align-self: center;
    }

    @media (min-width: $min-960) {
      @include grid-col(6);
      align-self: center;
    }

    @media (min-width: $min-1280) {
      @include grid-col(7);
      align-self: center;
    }
  }

  &__imageGrid {
    @include supports-grid() {
      display: grid;
      grid-auto-flow: row dense;
      grid-gap: 1.5rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-auto-rows: 1fr;

      @media (max-width: $max-600) {
        margin-left: -$gutter;
        margin-right: -$gutter;
      }

      @media (min-width: $min-720) {
        margin-left: $gutter;
      }

      @media (min-width: $min-960) {
        margin-left: 0;
        margin-right: -$gutter;
      }

      @media (min-width: $min-1280) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    @include no-supports-grid() {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: $max-600) {
        margin-left: -$gutter;
        margin-right: -$gutter;
      }

      @media (min-width: $min-720) {
        margin-left: $gutter;
      }

      @media (min-width: $min-960) {
        margin-left: 0;
        margin-right: -$gutter;
      }

      @media (min-width: $min-1280) {
      }
    }
  }

  &__contentCol {
    @include grid-col();
    margin-bottom: $gutter * 3;
    align-self: center;

    @media (min-width: $min-720) {
      @include grid-col(6);
      // @include grid-offset-right(1);
      align-self: center;
      margin-bottom: 0;
    }

    @media (min-width: $min-960) {
      @include grid-col(5);
      @include grid-offset-right(1);
      align-self: center;
    }

    @media (min-width: $min-1280) {
      @include grid-col(4);
      @include grid-offset-right(1);
      align-self: center;
    }
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h2;
    @include headingLine;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__text {
    @include grid-col();
  }

  &__copy {
    @include render-copy;
    color: #757575;

    :global(body.brand) & {
      color: $color-font;
    }
  }

  &__link {
    margin-top: 4rem;
    margin-bottom: -4rem;

    @media (min-width: $min-1080) {
      margin-top: 6rem;
      margin-left: -7rem;
    }
  }
}

.item {
  position: relative;

  & > div:last-child {
    position: absolute 0;
  }

  @include supports-grid() {
    grid-row: span 4;

    &:first-child {
      grid-row: 1 / 9;
      grid-column: 1 / 2;

      @media (min-width: $min-1280) {
        grid-row: 2 / 9;
        grid-column: 1 / 2;
      }
    }

    &:nth-child(2) {
      grid-row: 2 / 6;
      grid-column: 2 / 3;

      @media (min-width: $min-1280) {
        grid-row: 3 / 7;
        grid-column: 2 / 3;
      }
    }

    &:nth-child(6) {
      @media (min-width: $min-1280) {
        grid-row: 9 / 13;
        grid-column: 3 / 4;
      }
    }
  }

  @include no-supports-grid() {
    flex: 0 1 auto;
    flex-basis: calc(33% - 1rem);
    overflow: hidden;
    margin-bottom: 1rem;

    &:nth-child(3n-1) {
      transform: translateY(6rem);
    }

    &:nth-child(3n) {
      transform: translateY(-6rem);
    }
  }

  &__aspect {
    width: 100%;
    height: 0;
    padding-bottom: 92%;
  }

  &__img {
    position: absolute 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__figure {
    position: absolute 0;
  }
}
