@use 'styles/config' as *;
@use "sass:math";

.contact {
  &__segment {
    .full & {
      @include segment;
    }

    .split & {
      @include split-segment;
    }
  }

  &__container {
    .full & {
      @include container;
    }
  }

  &__row {
    @include grid-row;
  }

  &__col {
    @include grid-col;
  }

  &__title {
    @include h2();
    margin-bottom: 1.5em;
    max-width: 64rem;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__layout {
    @include grid-col();

    // .full & {
    //   @media (min-width: $min-960) {
    //     @include grid-col(10);
    //     @include grid-offset-left(1);
    //   }
    // }
  }

  &__content {
    @include grid-col();

    @media (min-width: $min-840) {
      @include grid-col(6);
    }
  }

  &__name {
    @include copy;
    max-width: 40rem;
    font-weight: 700;
    color: $color-font;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__copy {
    @include render-copy;
    max-width: 29rem;
    max-width: 29rem;

    p {
      color: $color-font;

      :global(body.dark) & {
        color: #fff;
      }
    }
  }

  &__phone {
    @include copy;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    color: $color-font;

    :global(body.dark) & {
      color: #fff;
    }

    &:not(:last-child) {
      margin-bottom: 3.5rem;
    }
  }

  &__button {
    margin-top: 2.5rem;
  }

  &__link {
    @include grid-col();
    margin-top: 5rem;

    @media (min-width: $min-840) {
      @include grid-col(6);
      margin-top: 0;
    }

    @media (min-width: $min-1280) {
      @include grid-col(6);
    }
  }

  &__linkbox {
    @include brandColor(background);
    position: relative;
    margin-left: -$gutter;
    padding: 4rem 5rem 4rem 3rem;
    min-height: 20rem;
    width: 40rem;
    max-width: calc(100% - 1rem);

    :global(body.brand) & {
      @include brandColorOverride(background, $color-dark);
      color: #fff;
    }

    @media (min-width: $min-720) {
      margin-left: 0;
      padding: 5rem 7rem 5rem 5rem;
      max-width: calc(100% - 4rem);
    }

    @media (min-width: $min-840) {
      width: 51.5rem;
      min-height: 30rem;
    }

    @media (min-width: $min-1080) {
      max-width: calc(100% - 5rem);
    }

    &Tagline {
      @include tagline;
      margin-bottom: 1rem;
    }

    &Title {
      @include reset-heading();
      max-width: 35rem;
      font-size: responsive(2.8rem, 4.2rem);
      font-weight: 600;
      letter-spacing: math.div(-0.8, 42) * 1em;
      line-height: math.div(48, 42);
    }

    &Button {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(50%, 50%);
    }
  }
}
