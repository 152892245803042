@use 'styles/config' as *;
@use "sass:math";

.timeline {
  :global(body.dark) & {
    color: #fff;
  }

  &__segment {
    @include segment;
  }

  &__container {
    @include container();
  }

  &__header {
    max-width: 60rem;
    margin-bottom: 4rem;

    @media (min-width: $min-720) {
      margin-bottom: 12rem;
    }
  }

  &__title {
    margin: 0.15em 0 0.3em;
    font-size: responsive(8rem, 12rem);
    font-weight: 700;
    line-height: 1;

    @media (min-width: $min-720) {
      margin: -0.15em 0 0.15em;
    }
  }

  &__text {
    font-size: 1.4rem;
    letter-spacing: math.div(0.58, 14) * 1em;
    line-height: math.div(24, 14);

    :global(body.dark) & {
      opacity: 0.8;
    }

    @include dropCap();
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();
  }

  &__list {
    @include grid-row();

    @media (min-width: $min-600) {
      @include grid-row(ltr, stretch, flex-start, $gutter);
    }

    @media (min-width: $min-720) {
      @include grid-row(ltr, stretch, flex-start, ($gutter * 2));
    }
  }
}

.item {
  @include grid-col();
  margin-bottom: 4rem;

  @media (min-width: $min-600) {
    @include grid-col(6, flex-end, $gutter);
    margin-bottom: 8rem;

    &:nth-child(2n) {
      transform: translateY(8rem);
    }
  }

  @media (min-width: $min-720) {
    @include grid-col(6, flex-end, ($gutter * 2));
  }

  @media (min-width: $min-1080) {
    @include grid-col(4, flex-end, ($gutter * 2));

    &:nth-child(2n) {
      transform: translateY(0);
    }

    &:nth-child(3n - 1) {
      transform: translateY(8rem);
    }
  }

  &__wrap {
    max-width: 40rem;
    margin: 0 auto;
  }

  &__image {
    position: relative;
    width: 100%;
  }

  &__aspec {
    @media (max-width: $max-600) {
      padding-bottom: 75%;
      width: 100%;
      height: 0;
    }
  }

  &__img {
    width: 100%;

    @media (max-width: $max-600) {
      position: absolute 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__category {
    @include tagline;
    @include trailingLine;
    padding: 1rem 0 0;

    @media (min-width: $min-720) {
      padding: 1.5rem 0 0.5rem;
    }
  }

  &__title {
    @include h3;
  }

  &__text {
    @include copy;
    margin-top: 2rem;

    :global(body.brand) & {
      color: $color-font;
    }
  }
}
