@use 'styles/config' as *;
@use "sass:math";

$header-height-mobile: 7rem;
$header-height-small: 10rem;
$header-height: 14rem;

.gallery {
  &.split {
    @media (max-width: $max-1080) {
      margin-left: -$container-gutter-tablet;
      margin-right: -$container-gutter-tablet;
    }
  }

  &__segment {
    .full & {
      @include segment();
    }

    .split & {
      @include split-segment();
    }

    .article & {
      @include article-segment();
    }
  }

  &__container {
    .article & {
      @media (min-width: $min-1080) {
        @include container();
      }
    }
  }

  &__row {
    .article & {
      @media (min-width: $min-1280) {
        @include grid-row();
      }
    }
  }

  &__col {
    .article & {
      @media (min-width: $min-1280) {
        @include grid-col(10);
        @include grid-offset-left(1);
      }
    }
  }

  &__wrap {
    position: relative;

    @include supports-grid() {
      display: grid;
      align-items: flex-end;
      grid-template-areas: 'carousel';

      @media (min-width: $min-1080) {
        align-items: flex-start;

        .article &,
        .split & {
          align-items: flex-end;
        }
      }
    }

    @include no-supports-grid() {
      display: block;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      z-index: 1;
      height: $header-height-mobile;
      min-width: 9rem;
      width: 10%;
      background: #fff;
      transition: background $transition-background;

      :global(body.dark) & {
        background-color: $color-dark;
      }

      :global(body.brand) & {
        @include brandColor(background-color);
      }

      @media (min-width: $min-720) {
        height: $header-height-small;
      }

      @media (min-width: $min-1080) {
        height: $header-height;
        width: 50%;

        .article &,
        .split & {
          height: $header-height-small;
          width: 0;
        }
      }

      @include no-supports-grid() {
        top: 0;
      }
    }
  }

  &__constraint {
    position: relative;
    z-index: 2;
    grid-area: carousel;

    .full &,
    .split & {
      @include container();
      width: 100%;
    }

    @media (min-width: $min-1080) {
      .split & {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @include no-supports-grid() {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      .article &,
      .split & {
        top: auto;
        bottom: 0;
      }
    }
  }

  &__header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    padding-right: $gutter;

    height: $header-height-mobile;

    background: #fff;
    transition: background $transition-background;

    :global(body.dark) & {
      color: #fff;
      background-color: $color-dark;
    }

    :global(body.brand) & {
      @include brandColor(background-color);
    }

    @media (min-width: $min-720) {
      height: $header-height-small;
    }

    @media (min-width: $min-1080) {
      height: $header-height;
      min-width: calc(50% + 1.5rem);

      .article &,
      .split & {
        height: $header-height-small;
        min-width: 0;
      }
    }
  }

  &__title {
    display: none;
    margin-right: $gutter;

    @media (min-width: $min-1080) {
      @include taglineWithLine;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__previous,
  &__next {
    @include reset-button;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.4rem;
    height: 4.4rem;
    cursor: pointer;

    svg {
      display: block;
      width: 1.5rem;
      height: 1.5rem;

      :global(body.dark) & {
        fill: #fff;
      }
    }

    &:hover svg {
      @include brandColor(fill);

      :global(body.brand) & {
        @include brandColorOverride(fill, #fff);
      }
    }
  }

  &__previous {
    transform: rotate(180deg);
  }

  &__position {
    font-weight: 700;
    font-size: 1.2rem;
    min-width: 5rem;
    text-align: center;

    @media (min-width: $min-720) {
      min-width: 7rem;
    }
  }

  &__carousel {
    grid-area: carousel;
    position: relative;

    overflow: hidden;
  }

  &__aspect {
    width: 100%;
    height: 0;
    padding-bottom: 100%;

    @media (min-width: $min-720) {
      padding-bottom: percentage(math.div(2, 3));
    }

    @media (min-width: $min-1440) {
      padding-bottom: 0;
      height: 90rem;
    }

    .split & {
      @media (min-width: $min-1080) {
        padding-bottom: 100%;
      }

      @media (min-width: $min-1440) {
        padding-bottom: 100%;
        height: 0;
      }
    }

    .article & {
      @media (min-width: $min-1080) {
        padding-bottom: percentage(math.div(2, 3));
      }

      @media (min-width: $min-1440) {
        padding-bottom: percentage(math.div(2, 3));
        height: 0;
      }
    }
  }

  &__list {
    @include reset-list();
    display: block;
  }
}

.item {
  position: absolute 0;
  opacity: 0;

  &.selected {
    // z-index: 1;
    opacity: 1;
  }

  &__img {
    position: absolute 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  // &__image {
  // }

  &__caption {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;

    @media (min-width: $min-840) {
      max-width: 60%;
    }

    @media (min-width: $min-1080) {
      top: auto;
      left: 0;
      right: auto;
      bottom: 0;
    }

    .article &,
    .split & {
      top: 0;
      left: 0;
      right: auto;
      bottom: auto;
    }
  }

  &__title {
    @include h4();
    margin-bottom: 1rem;
  }

  &__text {
    @include render-copy();
  }
}
