@use 'styles/config' as *;
@use "sass:math";

.button {
  @include reset-button();
  @include reset-anchor();

  display: inline-flex;
  align-items: center;
  position: relative;

  font-size: responsive(1.3rem, 1.6rem);
  font-weight: 600;
  color: $color-font;
  letter-spacing: math.div(0.2, 16) * 1em;
  // white-space: nowrap;
  text-align: left;

  cursor: pointer;

  transition-property: background-color, color;
  transition-duration: 200ms;

  :global(.dark-theme) &:not(.static),
  :global(body.dark) &:not(.static) {
    color: #fff;
  }

  &.dark.static {
    color: #fff;
  }

  svg {
    display: block;
    position: relative;
    top: 0.2em;
    // transform: translateY(-50%);

    transition-property: fill, transform;
    transition-duration: $transition-enter;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    margin-right: responsive(1.5rem, 3rem);

    svg {
      @include brandColor(fill);
      width: 1.1rem;
      height: 1.125em;

      :global(body.white) &:not(.static) & {
        @include brandColor(fill);
      }

      :global(body.dark) &:not(.static) & {
        @include brandColor(fill);
      }

      :global(body.brand) &:not(.static) & {
        @include brandColorOverride(fill, $color-dark);
      }

      .brand.static & {
        @include brandColorOverride(fill, $color-dark);
      }

      .iconDark & {
        fill: #000;
      }
    }
  }

  &:hover,
  &:focus {
    svg {
      transform: translateX(1rem);
    }
  }

  &__text {
    display: block;
  }
}
