@use 'styles/config' as *;
@use "sass:math";

.block {
  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();
  }

  &__layout {
    @include grid-row();
  }

  &.reverse &__layout {
    @include grid-row(rtl);
  }

  &__row {
    @include grid-row();
  }

  &__imageCol {
    @include grid-col();

    .globe & {
      @media (max-width: $max-720) {
        order: -1;
      }
    }

    @media (min-width: $min-720) {
      @include grid-col(5);
    }

    @media (min-width: $min-960) {
      @include grid-col(6);
    }
  }

  &__play {
    z-index: 2;
  }

  &__video {
    z-index: 1;
  }

  &__figure {
    position: relative;
    margin-left: -$gutter;
    margin-right: -$gutter;

    @media (min-width: $min-720) {
      margin: auto;
    }

    @media (min-width: $min-960) {
      margin-left: -$gutter;
    }
  }

  &.reverse &__figure {
    @media (min-width: $min-960) {
      margin-left: 0;
      margin-right: -$gutter;
    }
  }

  &__image {
    position: relative;
    max-height: 100%;

    @media (min-width: $min-480) {
      max-height: 60rem;
    }

    @media (min-width: $min-720) {
      max-height: none;
      margin: auto;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: math.div(640, 560) * 100%;
    }
  }

  &__img {
    position: absolute 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__contentCol {
    @include grid-col();
    margin-bottom: $gutter * 3;
    align-self: center;

    .globe & {
      margin-bottom: 0;
    }

    @media (min-width: $min-720) {
      @include grid-col(6);
      @include grid-offset-right(1);
      align-self: center;
      margin-bottom: 0;
    }

    @media (min-width: $min-960) {
      @include grid-col(5);
      @include grid-offset-right(1);
      align-self: center;
    }

    @media (min-width: $min-1280) {
      @include grid-col(5);
      @include grid-offset-right(1);
      align-self: center;
    }
  }

  &.reverse &__contentCol {
    @media (min-width: $min-720) {
      @include grid-offset-right(0);
      @include grid-offset-left(1);
    }

    @media (min-width: $min-960) {
      @include grid-offset-right(0);
      @include grid-offset-left(1);
    }

    @media (min-width: $min-1280) {
      @include grid-col(5);
      @include grid-offset-right(0);
      @include grid-offset-left(1);
      align-self: center;
    }
  }

  // &.reverse &__contentCol,
  // &:nth-child(2n) &__contentCol {
  //   @include grid-offset-right(0);
  //   @include grid-offset-left(1);
  // }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h2;
    @include headingLine;

    :global(.dark-theme) &,
    :global(body.dark) & {
      color: #fff;
    }
  }

  &__text {
    @include grid-col();

    @media (min-width: $min-960) {
      @include grid-col(percentage(math.div(4, 5)));
      @include grid-offset-left(percentage(math.div(1, 5)));
    }
  }

  // .reverse &__text {
  //   @media (min-width: $min-960) {
  //     @include grid-col(percentage(4/5));
  //     @include grid-offset-left(0));
  //   }
  // }

  &__copy {
    @include render-copy;
    color: #757575;

    :global(.dark-theme) & {
      color: #fff;
    }

    :global(body.brand) & {
      color: $color-font;
    }
  }

  &__buttons {
    @include grid-col();
    margin-top: 2rem;
    overflow: hidden;

    @media (min-width: $min-960) {
      @include grid-col(percentage(math.div(4, 5)));
      @include grid-offset-left(percentage(math.div(1, 5)));
    }
  }

  &__button {
    margin-top: 1rem;

    @media (min-width: $min-720) {
      margin-top: 2rem;
    }
  }

  &__link {
    margin-top: 4rem;
    margin-bottom: -4rem;

    @media (min-width: $min-1080) {
      margin-top: 6rem;
    }
  }

  &__globe {
    @media (max-width: $max-720) {
      width: 150%;
      margin-left: -25%;
      background: url('/static/images/globe-light.png') no-repeat center center;
      background-size: contain;

      :global(body.brand) &,
      :global(body.dark) & {
        background-image: url('/static/images/globe-dark.png');
      }

      &Wrap {
        display: none;
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: percentage(math.div(750, 862));
      }
    }

    @media (min-width: $min-720) {
      display: block;
      margin-left: -$gutter * 3;
    }

    @media (min-width: $min-960) {
      margin-left: -$gutter * 2;
    }

    @media (min-width: $min-1080) {
      margin-left: -$gutter * 3;
    }

    @media (min-width: $min-1200) {
      margin-left: -$gutter * 2;
    }

    @media (min-width: $min-1320) {
      margin-left: 0;
    }
  }
}
