@use 'styles/config' as *;
@use "sass:math";

.quote {
  &__segment {
    .full & {
      @include segment();
    }

    .split & {
      @include split-segment();
    }

    .article & {
      @include article-segment();
    }
  }

  &__container {
    .article &,
    .full & {
      @include container();
    }
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();

    .article &,
    .full & {
      @media (min-width: $min-720) {
        @include grid-col(11);
      }

      @media (min-width: $min-960) {
        @include grid-col(9);
        @include grid-offset-left(1);
      }

      @media (min-width: $min-1200) {
        @include grid-col(7);
        @include grid-offset-left(2);
      }
    }
  }

  &__copy {
    @include render-copy();
  }

  &__figure {
    @include reset-all;
  }

  &__quotewrap {
    .article &,
    .full & {
      @media (min-width: $min-720) {
        padding-left: 7rem;
        border-left: 0.2rem solid;
        border-color: brand();

        :global(body.brand) & {
          border-color: $color-dark;
        }
      }

      @media (min-width: $min-960) {
        padding-left: 8rem;
      }

      @media (min-width: $min-1200) {
        padding-left: 10.6rem;
      }
    }

    .split & {
      @media (min-width: $min-720) {
        padding-left: percentage(math.div(1, 12));
        border-left: 0.2rem solid;
        border-color: brand();
      }

      @media (min-width: $min-1080) {
        padding-left: calc(#{percentage(math.div(1, 5))} + #{math.div($gutter, 7)});
        border-left: 0.2rem solid;
        border-color: brand();
      }
    }

    .block & {
      // padding-left: 10.6rem;

      @media (min-width: $min-720) {
        padding-left: 5rem;
      }

      @media (min-width: $min-960) {
        padding-left: 8rem;
        border-left: 0.2rem solid;
        border-color: brand();
      }
    }
  }

  &__indent {
    @include grid-col;

    .article &,
    .full & {
      @media (min-width: $min-720) {
        @include grid-col(9);
        @include grid-offset-left(1);
      }
      @media (min-width: $min-960) {
        @include grid-col(7);
        @include grid-offset-left(2);
      }
    }
  }

  &__blockquote {
    @include blockquote;

    .block & {
      @media (min-width: $min-720) {
        padding-right: 4.5rem;

        &::after {
          content: '”';
          @include brandColor(color);
          position: absolute;
          bottom: -0.5em;
          right: 0;
          font-size: 3.1em;
          font-weight: 700;
          line-height: 1;

          :global(body.brand) & {
            color: $color-dark;
          }
        }
      }
    }
  }

  &__attribution {
    @include copy();
    padding-left: 3.5rem;

    @media (min-width: $min-720) {
      padding-left: 0;
    }

    .full & {
      margin-top: 5rem;
    }

    .article &,
    .full & {
      @media (min-width: $min-720) {
        padding-left: 7rem;
      }

      @media (min-width: $min-960) {
        padding-left: 8rem;
      }

      @media (min-width: $min-1200) {
        padding-left: 10.6rem;
      }
    }

    .split & {
      @media (min-width: $min-720) {
        padding-left: percentage(math.div(1, 12));
      }

      @media (min-width: $min-1080) {
        padding-left: calc(#{percentage(math.div(1, 5))} + #{math.div($gutter, 6)});
      }
    }

    .block & {
      @media (min-width: $min-720) {
        padding-left: 5rem;
      }

      @media (min-width: $min-960) {
        padding-left: 8rem;
      }
    }
  }
}
