@use 'styles/config' as *;

.select {
  position: relative;
  margin-bottom: 3.5rem;

  &__label {
    @include formLabel();
  }

  &__input {
    @include formInput();
    padding-right: 3rem;
  }

  &__dropdown {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0.5rem;

      margin: auto;

      height: 0;
      width: 0;

      border: 0.5rem solid transparent;
      border-top-width: 0.6rem;
      border-top-color: $color-dark;

      :global(body.dark) & {
        border-top-color: #fff;
      }
    }

    select::-ms-expand {
      display: none;
    }
  }

  &__message {
    @include copy(1rem, 1.1rem);
    position: absolute;
    top: 0;
    right: 0;
  }
}
