@use 'styles/config' as *;
@use "sass:math";

.pipeline {
  &__segment {
    @include segment-bottom();
    padding-top: responsive(4rem, 8rem);
  }

  &__container {
    @include container();
  }

  &__row {
    @include grid-row;
  }

  &__title {
    > * {
      @include h2();
      margin: 0 0 4rem;
      max-width: 9em;
    }
  }

  &__text {
    @include grid-col();

    @media (min-width: $min-960) {
      @include grid-col(6);
      @include grid-offset-left(1);
    }

    @media (min-width: $min-1280) {
      @include grid-col(5);
      @include grid-offset-left(1);
    }

    @media (min-width: $min-1440) {
      @include grid-col(4);
      @include grid-offset-left(1);
    }

    @include render-copy();

    // margin: 0 0 4rem;
    max-width: 50rem; // temp

    font-size: 1.8rem;
    font-weight: 300;
    line-height: math.div(30, 18);

    :global(body.dark) & {
      color: #f4f4f4;
    }
  }

  &__items {
    @include reset-list;
  }

  &__footnotes {
    font-size: 1.2rem;
    padding: responsive(1.5rem, 3rem);
    background: $color-background-variant-light;
    color: $color-copy;
    letter-spacing: 0.063em;

    > p {
      margin: 0 0 0.3rem;
    }
  }

  &__table {
    width: 100%;

    @media (max-width: $max-720) {
      width: calc(100% + 6rem);
      margin-left: -$gutter;
      margin-right: -$gutter;
      overflow: auto;
    }
  }
}

.pipelineItem {
  &__cell {
    padding: 0 !important;

    &:last-child {
      padding-right: 3rem !important;
    }
  }

  &__progress {
    overflow: hidden;
    height: responsive(1.2rem, 1.8rem);
    background: #eee;
    box-shadow: inset 0 1.2rem 0.26rem -0.8rem rgba(#000, 0.23);

    :global(.dark-theme) & {
      background: #fff;
    }

    &.first {
      border-radius: 1rem 0 0 1rem;
    }

    &.last {
      border-radius: 0 1rem 1rem 0;
      box-shadow: inset -0.2rem 1.2rem 0.26rem -0.8rem rgba(#000, 0.23);
    }
  }

  &__fill {
    height: 100%;
    // width: responsive(1.2rem, 1.8rem);
    background: brand();
    box-shadow: 0 0 0 0.1rem brand();

    [aria-label='true'] & {
      width: 50%;
      border-radius: 0 1rem 1rem 0;
      box-shadow: 0.2rem 0 0.26rem 0 rgba(#000, 0.23);
    }
  }

  &__cell[aria-label='true']:last-child &__fill {
    width: 100%;
  }

  &__cell[aria-label='true'] ~ &__cell &__fill {
    display: none;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;

  text-align: left;

  &__thead {
    text-wrap: wrap;

    th {
      padding: 0 4rem responsive(1.5rem, 3rem, 84rem) 0;

      font-size: responsive(1rem, 1.2rem, 84rem);
      font-weight: 700;
      color: $color-font-light;
      text-transform: uppercase;
      text-align: center;
      vertical-align: top;

      &:first-child {
        padding-left: 2rem;

        @media (min-width: $min-720) {
          padding-left: 0;
        }
      }

      &:nth-child(2) {
        padding-left: responsive(1rem, 2rem, 84rem);
      }

      &:last-child {
        padding-right: 2rem;

        @media (min-width: $min-720) {
          padding-right: 3rem;
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        text-align: left;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  &__tbody {
    @media (min-width: $min-720) {
      box-shadow: 0 -5rem 20rem 5rem rgba(0, 0, 0, 0.03);

      :global(.dark-theme) & {
        box-shadow: none;
      }
    }

    tr {
      td,
      th {
        padding: responsive(1.8rem, 2.4rem, 84rem) responsive(0.5rem, 2rem, 84rem);
        border-bottom: 1px solid $color-border;

        :global(.dark-theme) & {
          border-bottom-color: rgba(#e8e8e8, 0.24);
        }

        &:first-child {
          padding-left: responsive(2rem, 1rem, 84rem);
        }

        &:last-child {
          padding-right: 0;
        }
      }

      &:first-child {
        td,
        th {
          border-top: 1px solid $color-border;

          :global(.dark-theme) & {
            border-top-color: rgba(#e8e8e8, 0.24);
          }
        }
      }
    }

    th {
      font-size: responsive(1.2rem, 1.7rem, 84rem);
      font-weight: 600;
    }

    td {
      font-size: responsive(1.2rem, 1.6rem, 84rem);
      font-weight: 500;
      color: $color-font-light;
    }
  }
}

.pipeline__items.noCopy .pipelineItem:first-child {
  @include segment-top(0, 0);
}
