@use 'styles/config' as *;
@use "sass:math";

.media {
  @include reset-all();
  @include copy();
  break-inside: avoid;
  margin-bottom: 2rem;

  img {
    margin: 0 auto;
  }

  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__title {
    @include h2();
    margin-bottom: 1em;
    max-width: 64rem;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();
  }

  &__aspect {
    position: relative;
    padding-bottom: percentage(math.div(9, 16));
    width: 100%;
    height: 0;

    box-shadow: inset 0 0 0 1px rgba(#000, 0.1);

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__ready {
    opacity: 0;
    transition: opacity 1s;
    // transition-delay: 200ms;

    &.visible {
      opacity: 1;
    }
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 300ms;

    svg {
      // @include brandColor(fill);
      fill: #fff;

      width: 7rem;
      height: 7rem;
    }

    &:hover {
      opacity: 1;
    }

    // &::after {
    //   content: 'click me';
    //   color: #fff;
    // }

    &.disabled {
      pointer-events: none;
      transition: opacity 500ms;

      &:hover {
        opacity: 0;
      }
    }
  }

  &__caption {
    padding: 0.5em 0;
    font-size: 0.8em;
    font-weight: 400;
    color: $color-font;

    border-bottom: 1px solid $color-border;
  }
}
