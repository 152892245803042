@use 'styles/config' as *;
@use "sass:math";

.author {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  &.inline {
    @media (min-width: $min-600) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__image {
    flex: 0 0 auto;
    display: flex;
    overflow: hidden;
    max-width: 32rem;

    margin-bottom: 3rem;

    transition: background-color $transition-background;

    :global(body.dark) & {
      background-color: $color-dark;
    }

    > img {
      object-fit: cover;
      transition: filter $transition-background;

      :global(body.dark) & {
        mix-blend-mode: screen;
        filter: invert(100%);
      }

      :global(body.brand) & {
        mix-blend-mode: multiply;
      }
    }
  }

  &__img {
    object-fit: cover;
    transition: filter $transition-background;

    :global(body.dark) & {
      mix-blend-mode: screen;
      filter: invert(100%);
    }

    :global(body.brand) & {
      mix-blend-mode: multiply;
    }
  }

  &__text {
    flex: 0 1 auto;
    font-size: responsive(1.2rem, 1.4rem);
    color: $color-font;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__name {
    @include reset-paragraph;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: math.div(0.78, 14) * 1em;
    line-height: math.div(24, 14);
  }

  &__position {
    margin-top: 0.05em;
    font-weight: 300;
    line-height: math.div(24, 14);

    p {
      @include reset-paragraph;
    }
  }
}
