@use 'styles/config' as *;

.checkbox {
  position: relative;
  margin-bottom: 3.5rem;

  &.radio + &.radio {
    margin-top: -2rem;
  }

  &__input {
    /* Base for label styling */
    &:not(:checked),
    &:checked {
      opacity: 0.001;
      position: absolute;
      bottom: 0;
      pointer-events: none;
      // left: -9999px;
    }

    &:not(:checked) + label,
    &:checked + label {
      position: relative;
      padding-left: 3.25em;
      cursor: pointer;
    }

    /* checkbox aspect */
    &:not(:checked) + label:before,
    &:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 2.1rem;
      height: 2.1rem;
      background: transparent;
      border: 1px solid $color-copy;

      .radio & {
        border-radius: 50%;
      }

      :global(body.brand) & {
        border: 1px solid $color-font;
      }
    }

    &:checked + label:before {
      @include brandColor;
      @include brandColor(border-color);

      :global(body.brand) & {
        @include brandColorOverride(background, #fff);
        @include brandColorOverride(border-color, #fff);
      }
    }

    &:not(:checked) + label:before {
      // background: red;
    }

    /* checked mark aspect */
    &:not(:checked) + label:after,
    &:checked + label:after {
      content: '';

      position: absolute;
      opacity: 1;

      top: 0.55rem;
      left: 0.45rem;

      width: 1.2rem;
      height: 0.7rem;

      border-left: 2px solid $color-dark;
      border-bottom: 2px solid $color-dark;

      transform: rotate(-45deg);

      // transition-property: left;
      // transition-duration: 150ms;

      .radio & {
        top: 0.7rem;
        left: 0.7rem;
        width: 0.7rem;
        height: 0.7rem;
        border: none;
        border-radius: 50%;
        background-color: $color-dark;
      }
    }

    &:not(:checked) + label:after {
      opacity: 0;
    }

    &:not(:checked):focus + label:before,
    &:checked:focus + label:before {
      outline: 3px solid #ddd;
    }

    // &:not(:checked):focus + label,
    // &:checked:focus + label {
    //   color: $color-font;

    //   :global(body.dark) & {
    //     color: #fff;
    //   }

    //   :global(body.brand) & {
    //     color: #000;
    //     text-decoration: underline;
    //   }
    // }

    /* hover style just for information */
    // label:hover:before {
    //   border: 2px solid #4778d9!important;
    // }
  }

  &__label {
    font-size: responsive(1.2rem, 1.4rem);
    color: $color-copy;
    display: block;
    user-select: none;

    :global(body.brand) & {
      color: $color-font;
    }

    a {
      @include anchor;
    }
  }

  &__message {
    @include copy(1rem, 1.1rem);
    position: absolute;
    top: 2.7rem;
    left: 1.05rem;

    padding: 0.2rem 0.5rem;

    color: $color-dark;

    transform: translate(-50%, 0);

    box-shadow: 0 0 0 1px #fff;

    @include brandColor(background-color);

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: -1.1rem;
      left: 0;
      right: 0;
      margin: auto;

      width: 0;

      border: 0.5rem solid transparent;
      border-bottom-color: #fff;
    }

    &::after {
      top: -1rem;
      @include brandColor(border-bottom-color);
    }
  }
}
