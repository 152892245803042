@use 'styles/config' as *;
@use "sass:math";

.video {
  &__wrap {
    @media (max-width: $max-720) {
      margin-left: -$gutter;
      margin-right: -$gutter;
    }
  }

  &__figure {
    @include reset-all;
  }

  &__aspect {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__embed {
    @include fit();

    iframe {
      @include fit();
      width: 100%;
      height: 100%;
    }
  }

  &__caption {
    margin-top: 0.3rem;
  }

  &__title {
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 2.4rem;
    letter-spacing: math.div(1, 12) * 1em;
  }

  &__thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;

    img {
      @include fit;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
