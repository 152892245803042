@use 'styles/config' as *;

.signup {
  position: relative;
  margin-bottom: 3.5rem;
  max-width: 43rem;

  &__label {
    @include formLabel;

    &.hidden {
      display: none;
    }
  }

  &__wrap {
    @include formInput();
    display: flex;
  }

  &__input {
    @include formInput();
  }

  &__button {
    @include reset-button();
    // position: absolute;
    // top: 0;
    margin-right: -1.4rem;
    bottom: 0.5rem;
    width: 4.4rem;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      top: -0.6rem;
      bottom: -0.4rem;
    }

    svg {
      width: 1.1rem;
      height: 1.8rem;
      fill: $color-dark;

      :global(body.dark) & {
        @include brandColor(fill);
      }
    }

    &:hover svg {
      :global(body.white) & {
        @include brandColor(fill);
      }

      :global(body.dark) &,
      :global(body.brand) & {
        @include brandColorOverride(fill, #fff);
      }
    }
  }
}
