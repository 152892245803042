@use 'styles/config' as *;
@use "sass:math";

.infographic {
  &__splitSegment {
    @include split-segment;
  }

  &__figure {
    @include reset-all;
    @include brandColor(border-color);
    padding-left: 3.5rem;
    padding-left: Min(7vw, 3.5rem);

    color: $color-font;

    border-left: 0.2rem solid;

    :global(body.dark) & {
      color: #fff;
    }

    :global(body.brand) & {
      @include brandColorOverride(border-color, $color-dark);
    }
  }

  &__splitSegment &__figure {
    @media (min-width: $min-720) {
      padding-left: calc(#{percentage(math.div(1, 12))} - #{$gutter});
    }

    @media (min-width: $min-1080) {
      padding-left: calc(#{percentage(math.div(1, 5))} - #{$gutter});
    }
  }

  &__number {
    @include reset-paragraph;
    font-size: responsive(3.2rem, 4.8rem);
    font-weight: 600;
    line-height: 1;
  }

  &__caption {
    margin-top: 3rem;

    max-width: 38rem;
    font-size: responsive(1.4rem, 1.6rem);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: math.div(0.89, 16) * 1em;

    p {
      @include reset-paragraph;
    }
  }

  &__image {
    // flex: 0 0 auto;
    // display: flex;
    // overflow: hidden;
    // max-width: 38rem;

    // transition: background-color $transition-background;

    :global(body.dark) & {
      background-color: $color-dark;
    }

    :global(body.brand) & {
      background-color: brand();
    }
  }

  &__img {
    object-fit: cover;
    transition: filter $transition-background;

    :global(body.dark) & {
      mix-blend-mode: screen;
      filter: invert(100%);
    }

    :global(body.brand) & {
      mix-blend-mode: multiply;
    }
  }
}

.infographics {
  &__segment {
    @include segment;
  }

  &__container {
    @include container;
  }

  &__header {
    max-width: 60rem;
    margin-bottom: 8rem;
  }

  &__title {
    @include h2;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__text {
    @include copy;
  }

  // captialized min() to avoid SCSS conflict
  &__list {
    @include supports-grid() {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(auto-fit, minmax(Min(100%, 30rem), 1fr));
      column-gap: $gutter * 2;
      row-gap: $gutter * 3;

      > * {
        max-width: 50rem;
      }
    }

    @include no-supports-grid() {
      display: flex;
      flex-wrap: wrap;
      margin: -$gutter;

      > * {
        flex: 1 1 math.div(100%, 3);
        min-width: Min(100%, 30rem);
        padding: 0 $gutter $gutter * 3;
      }
    }
  }
}
