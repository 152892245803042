@use 'styles/config' as *;

.documents {
  &__segment {
    .full & {
      @include segment();
    }

    .split & {
      @include split-segment();
    }

    .article & {
      @include article-segment();
    }
  }

  &__container {
    .full &,
    .article & {
      @include container();
    }
  }

  &__title {
    @include h2();
    margin-bottom: 2em;
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();

    .article &,
    .full & {
      @media (min-width: $min-720) {
        @include grid-offset-left(1);
      }
      @media (min-width: $min-960) {
        @include grid-col(10);
        @include grid-offset-left(2);
      }
    }
  }

  &__list {
    &::before {
      content: '';
      display: block;
      width: 7.7rem;
      min-width: 3.6rem;
      max-width: 10vw;
      height: 0.2rem;
      margin-bottom: 2.3rem;
      grid-column: span 2;
      background-color: brand();

      :global(body.brand) & {
        background-color: #000;
      }
    }

    .article &,
    .full & {
      @media (min-width: $min-840) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-auto-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: $gutter * 2;
      }
    }
  }
}

.item {
  @include copy();

  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;

  &__icon {
    flex: 0 0 auto;
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 2.5rem;

    :global(body.dark) & {
      svg path {
        stroke: #fff;
      }
    }
  }

  &__link {
    @include reset-anchor();
    display: flex;
    align-items: center;
    color: $color-dark;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    transition: color $transition-enter;

    :global(body.dark) & {
      color: #fff;
    }

    &:hover {
      @include brandColor(color);

      :global(body.dark) & {
        @include brandColor(color);
      }

      :global(body.brand) & {
        color: #fff;
      }
    }
  }

  &__info {
    display: flex;
    font-size: 1.6rem;
  }

  &__filetype {
    margin-right: 1em;

    &::before {
      content: 'File type: ';
    }
  }

  &__filesize {
    &::before {
      content: 'File size: ';
    }
  }
}
