@use 'styles/config' as *;
@use "sass:math";

.overlap {
  display: block;

  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();
  }
}

.item {
  margin-bottom: 12rem;

  @media (min-width: $min-720) {
    margin-bottom: 0;
  }

  & + & {
    @media (min-width: $min-720) {
      margin-top: 8rem;
    }

    @media (min-width: $min-960) {
      margin-top: -4rem;
    }
  }

  &__layout {
    @include grid-row();
  }

  &.reverse &__layout,
  &:nth-child(2n) &__layout {
    @include grid-row(rtl);
  }

  &.reverse:nth-child(2n) &__layout {
    @include grid-row(ltr);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__row {
    @include grid-row();
  }

  &__imageCol {
    @include grid-col();

    @media (min-width: $min-720) {
      @include grid-col(5);
    }

    @media (min-width: $min-960) {
      @include grid-col(6);
    }

    & > * {
      height: 100%;
    }
  }

  &__play {
    z-index: 2;
  }

  &__video {
    z-index: 1;
  }

  &__figure {
    position: relative;
    // max-height: 43rem;
    height: 100%;
    margin-left: -$gutter;
    margin-right: -$gutter;

    @media (min-width: $min-720) {
      max-height: none;
      height: auto;
      margin: auto;
    }

    @media (min-width: $min-960) {
      margin-left: -$gutter;
      height: 100%;
    }
  }

  &.reverse &__figure,
  &:nth-child(2n) &__figure {
    @media (min-width: $min-960) {
      margin-left: 0;
      margin-right: -$gutter;
    }
  }

  &.reverse:nth-child(2n) &__figure {
    @media (min-width: $min-960) {
      margin-left: -$gutter;
      margin-right: 0;
    }
  }

  &__image {
    position: relative;
    max-height: 43rem;
    // max-height: 100%;

    @media (min-width: $min-480) {
      max-height: 60rem;
    }

    @media (min-width: $min-720) {
      max-height: none;
      height: 100%;
      margin: auto;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: math.div(640, 560) * 100%;
    }
  }

  &__contentCol {
    @include grid-col();
    margin-bottom: $gutter * 3;
    align-self: center;

    @media (min-width: $min-720) {
      @include grid-col(6);
      @include grid-offset-right(1);
      align-self: center;
      margin-bottom: 0;
    }

    @media (min-width: $min-960) {
      @include grid-col(4);
      @include grid-offset-right(1);
      @include grid-offset-left(1);
      align-self: center;
      padding-top: 12rem + 4rem;
      padding-bottom: 12rem + 4rem;
    }
  }

  &.reverse &__contentCol,
  &:nth-child(2n) &__contentCol {
    @media (min-width: $min-720) and (max-width: $max-960) {
      @include grid-offset-left(1);
      @include grid-offset-right(0);
    }
  }

  &.reverse:nth-child(2n) &__contentCol {
    @media (min-width: $min-720) and (max-width: $max-960) {
      @include grid-offset-left(0);
      @include grid-offset-right(1);
    }
  }

  &:first-child &__contentCol {
    @media (min-width: $min-960) {
      padding-top: 12rem;
    }
  }

  &:last-child &__contentCol {
    @media (min-width: $min-960) {
      padding-bottom: 12rem;
    }
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h2;
    margin: 0 0 2rem;

    @media (min-width: $min-720) {
      margin: 0 0 4rem;
    }

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__text {
    @include grid-col();
  }

  &__copy {
    @include render-copy;
    // color: #757575;

    @media (min-width: $min-960) {
      margin-right: -$gutter;
    }

    :global(body.brand) & {
      // color: $color-font;
    }
  }

  &__buttons {
    margin-top: 2rem;

    @media (min-width: $min-720) {
      margin-top: 4rem;
    }

    // &.inline {
    //   display: flex;
    //   margin-left: -4rem;

    //   > * {
    //     margin-left: 4rem;
    //   }
    // }
  }

  &__link {
    margin-top: 1rem;

    @media (min-width: $min-720) {
      margin-top: 2rem;
    }
  }
}
