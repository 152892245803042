@use 'styles/config' as *;
@use "sass:math";

.newsletter {
  @include backgroundColor;
  // position: relative;
  // overflow: hidden;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   height: 6rem;
  //   opacity: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: linear-gradient(rgba($color-dark, 0), rgba($color-dark, 1));
  //   transition: opacity 300ms;

  //   :global(body.dark) & {
  //     opacity: 1;
  //     transition: opacity 600ms 100ms;
  //   }
  // }

  &__segment {
    @include segment();
    position: relative;
  }

  &__container {
    @include container();
  }

  &__header {
    // text-align: center;
  }

  &__title {
    @include h2();
    margin: 0 0 4rem;
  }

  &__row {
    @include grid-row();
  }

  &__content {
    @include grid-col();

    @media (min-width: $min-840) {
      @include grid-col(5);
    }
  }

  &__figure {
    display: none;
    position: relative;

    @include grid-col(6);
    @include grid-offset-left(1);

    @media (min-width: $min-840) {
      display: flex;
      justify-content: center;
    }

    & > div {
      width: 100%;
    }
  }

  &__image {
    position: absolute 0;
    // transform: translateY(10rem);

    // top: -$segment-padding-mobile;
    bottom: math.div(-$segment-padding-mobile, 2);

    background: center bottom url('/static/images/envelope-masked.png') no-repeat;
    background-size: contain;

    @media (min-width: $min-720) {
      // top: -$segment-padding;
      bottom: math.div(-$segment-padding, 2);
    }
  }

  &__content {
  }
}
