@use 'styles/config' as *;
@use "sass:math";

.block {
  $self: &;
  overflow: hidden;

  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__layout {
    @include supports-grid {
      display: grid;
      grid-auto-flow: row;
      column-gap: $gutter;
      row-gap: 7.5rem;
      grid-template-columns: 1fr;
      align-items: center;

      @media (min-width: $min-1280) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-template-areas: 'box box box box box box box . aside aside aside aside';

        #{ $self }.full & {
          grid-template-columns: 1fr;
          grid-template-areas: 'box';
        }
      }

      @media (min-width: $min-1600) {
        grid-template-areas: 'box box box box box box . aside aside aside aside aside';
      }
    }

    @include no-supports-grid {
      @include grid-row;
    }
  }

  &__box {
    @media (min-width: $min-1280) {
      grid-area: box;
      align-self: flex-start;
    }

    #{ $self }.none & {
      display: block;

      @include no-supports-grid {
        @include grid-col(6);
      }
    }

    #{ $self }:not(.none) & {
      @include supports-grid {
        display: grid;
        padding: 7rem $container-gutter-mobile;
        margin-left: -$container-gutter-mobile;
        margin-right: -$container-gutter-mobile;

        @media (min-width: $min-720) {
          grid-template-columns: repeat(12, minmax(0, 1fr));
          grid-template-areas:
            '. header header header header header header header header header header .'
            '. text text text text text text text text text text .';
          padding: 10rem 0 9rem;
          margin-left: 0;
          margin-right: 0;
        }

        @media (min-width: $min-1280) {
          grid-template-columns: repeat(7, minmax(0, 1fr));
          grid-template-areas:
            '. header header header header header .'
            '. text text text text text .';
          margin-right: -6rem;
        }

        @media (min-width: $min-1600) {
          display: block;
          margin-right: 0;
          margin-left: -9rem;
          padding: 12rem 9rem 12rem 9rem;
        }
      }

      @include no-supports-grid {
        @include grid-col(6);
        padding: 8rem 6rem 8rem 6rem;
        margin-right: 0;
      }
    }

    #{ $self }.full.none & {
      @include supports-grid {
        display: grid;

        @media (min-width: $min-720) {
          grid-template-columns: 1fr;
          grid-template-areas:
            'header'
            'text';
        }

        @media (min-width: $min-960) {
          grid-template-columns: 1fr;
          grid-template-areas:
            'header'
            'text';
        }

        @media (min-width: $min-1280) {
          grid-template-columns: repeat(12, minmax(0, 1fr));
          column-gap: $gutter;
          grid-template-areas:
            'header header header header header header header header header header header .'
            '. text text text text text text text text text text .';
        }
      }

      @include no-supports-grid {
        @include grid-col;
      }
    }

    #{ $self }.full:not(.none) & {
      @include supports-grid {
        @media (min-width: $min-720) {
          grid-template-columns: repeat(12, minmax(0, 1fr));
          column-gap: $gutter;
          grid-template-areas:
            '. header header header header header header header header header header .'
            '. text text text text text text text text text text .';
          margin-right: 0;
        }

        @media (min-width: $min-1600) {
          display: grid;
          grid-template-areas:
            'header header header header header header header header header header header .'
            '. text text text text text text text text text text .';
          margin-left: -9rem;
          padding: 12rem 0 12rem 9rem;
        }
      }

      @include no-supports-grid {
        @include grid-col;
        padding: 8rem 6rem 8rem 6rem;
      }
    }

    .brand & {
      color: $color-dark;
      background-color: brand();

      :global(body.brand) & {
        box-shadow: 0 0 0 0.2rem #fff;
      }
    }

    .white & {
      color: $color-dark;
      background-color: #fff;

      :global(body.white) & {
        box-shadow: 0 0 0 0 brand(0.2);
      }
    }

    .dark & {
      color: #fff;
      background-color: $color-dark;

      :global(body.dark) & {
        border-color: #fff;
        border-width: 0.2rem;
        border-style: solid;
      }
    }
  }

  &__aside {
    max-width: 68rem;

    @include supports-grid {
      @media (min-width: $min-1280) {
        grid-area: aside;
      }
    }

    @include no-supports-grid {
      @include grid-col(5);
      @include grid-offset-left(1);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 1em;
    overflow: hidden;
    font-size: responsive(3rem, 5.2rem);

    > * {
      width: 100%;
    }

    @media (min-width: $min-720) {
      grid-area: header;
      border-left: 0.2rem solid #fff;
      padding: 3rem 0 2rem (math.div(35, 52) * 1em);
      margin-bottom: math.div(75, 52) * 1em;
    }

    .white & {
      border-left-color: brand();
    }

    .brand & {
      color: brandFont();
    }

    #{ $self }.none & {
      :global(body.white) & {
        border-left-color: brand();
      }
    }
  }

  &__title {
    @include h2();
    color: inherit;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column;
    height: auto;
    max-width: 84rem;

    @include no-supports-grid {
      @include h3();
      color: inherit;
    }

    @media (min-width: $min-480) {
      flex-flow: row;
      align-items: center;
    }

    svg {
      flex: 0 0 auto;
      align-self: flex-start;
      display: block;
      margin-bottom: 0.8em;
      width: math.div(48, 52) * 1em;
      min-width: 4rem;

      @media (min-width: $min-480) {
        margin-top: math.div(5, 52) * -1em;
        margin-right: math.div(30, 52) * 1em;
        margin-bottom: 0;
      }
    }

    #{ $self }.none & {
      :global(body.white) & {
        svg path:last-child {
          fill: brand();
        }
      }

      :global(body.dark) & {
        color: #fff;

        svg path:first-child {
          fill: brand();
        }
      }
    }

    .dark & {
      color: #fff;

      svg path {
        &:first-child {
          fill: brand();
        }
      }
    }

    .white & {
      svg path {
        &:last-child {
          fill: brand();
        }
      }
    }
  }

  &__text {
    @media (min-width: $min-720) {
      grid-area: text;
    }
  }

  &__copy {
    @include render-copy;
    max-width: 95rem;

    #{ $self }.twocol:not(.full) & {
      max-width: none;

      @media (min-width: $min-840) and (max-width: $max-1280) {
        columns: 2;
        column-gap: $gutter * 2;
      }
    }

    #{ $self }.twocol.full.none & {
      max-width: none;

      @media (min-width: $min-720) {
        columns: 2;
        column-gap: $gutter * 2;
      }

      @media (min-width: $min-1200) {
        column-gap: $gutter * 4;
      }
    }

    #{ $self }.twocol.full:not(.none) & {
      max-width: none;

      @media (min-width: $min-960) {
        columns: 2;
        column-gap: $gutter * 2;
      }

      @media (min-width: $min-1200) {
        column-gap: $gutter * 4;
      }
    }

    p {
      .brand & {
        color: brandFont();
      }

      .dark & {
        color: #fff;
      }

      #{ $self }.dark & {
        :global(body.dark) & {
          color: $color-copy;
        }
      }

      #{ $self }.dark & {
        :global(body.brand) & {
          color: #fff;
        }
      }

      #{ $self }.brand & {
        a:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
    }

    ul {
      li {
        .brand & {
          color: $color-dark;

          &::before {
            @include brandColorOverride(background-color, $color-dark);
          }
        }

        .dark & {
          color: #fff;

          &::before {
            @include brandColor(background-color);
          }
        }

        #{ $self }.dark & {
          :global(body.dark) & {
            color: $color-copy;
          }
        }

        #{ $self }.dark & {
          :global(body.brand) & {
            color: #fff;
          }
        }
      }
    }
  }
}
