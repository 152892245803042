@use 'styles/config' as *;
@use "sass:math";

.medialist {
  &__segment {
    @include segment;
  }

  &__container {
    @include container;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 5.5rem;
  }

  &__headline {
  }

  &__title {
    @include h3;
    margin-top: -0.5rem;
  }

  &__download {
    @include reset-anchor;
    display: flex;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: math.div(0.5, 12) * 1em;
    font-weight: 700;
    cursor: pointer;

    svg {
      display: block;
      margin-right: 1.5rem;
      width: 2rem;
      height: 2rem;
    }

    &:hover {
      @include brandColor(color);
    }
  }

  &__list {
    @include grid-row;
    margin-bottom: -$gutter;
  }
}

.item {
  @include grid-col;
  margin-bottom: $gutter;

  @media (min-width: $min-480) {
    @include grid-col(6);
  }

  .two & {
    @media (min-width: $min-720) {
      @include grid-col(4);
    }
  }

  .three & {
    @media (min-width: $min-720) {
      @include grid-col(4);
    }
  }

  .four & {
    @media (min-width: $min-720) {
      @include grid-col(6);
    }

    @media (min-width: $min-960) {
      @include grid-col(3);
    }
  }

  .many & {
    @media (min-width: $min-720) {
      @include grid-col(4);
    }

    @media (min-width: $min-1280) {
      @include grid-col(20%);
    }
  }

  &__link {
    @include reset-anchor();
    color: $color-dark;

    :global(.dark-theme) & {
      color: #fff;
    }

    &:hover {
      @include brandColor(color);
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;
    min-height: 20rem;

    &::after {
      content: '';
      position: absolute 0;
      box-shadow: inset 0 0 0 0.1rem #e5e5e5;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: percentage(math.div(3, 4));

      .portrait & {
        padding-bottom: percentage(math.div(4, 3));
      }
    }
  }

  &__img {
    position: absolute 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition-property: transform;
    transition-duration: 1400ms;
  }

  &:hover &__img {
    transform: scale(1.1);
    transition-duration: 600ms;
  }

  &__icon {
    @include brandColor();
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 6rem;
    height: 6rem;
    opacity: 0;

    transform: translateY(0.5rem);

    transition-property: opacity, transform;
    transition-duration: 200ms;

    svg {
      display: block;
      width: 2rem;

      :global(.adalvo) & {
        path {
          stroke: brandFont($defaultColor: $color-copy);
        }
      }
    }
  }

  &:hover &__icon {
    opacity: 1;
    transform: translateY(0);
  }

  &__title {
    @include h5;
    font-weight: 600;
    letter-spacing: math.div(-0.2, 18) * 1em;

    transition-property: color;
    transition-duration: 200ms;
  }

  &__subline {
    @include copy(1.4rem, 1.4rem);
    margin-top: 0.1rem;
  }

  &__info {
    display: flex;
    font-size: 1.6rem;
  }

  &__filetype {
    margin-right: 1em;

    &::before {
      content: 'File type: ';
    }
  }

  &__filesize {
    &::before {
      content: 'File size: ';
    }
  }
}
