@use 'styles/config' as *;
@use "sass:math";

.columns {
  :global(body.dark) & {
    color: #fff;
  }

  &__segment {
    @include segment();
    overflow: hidden;
  }

  &__wrap {
    .hasImage.brand & {
      @include brandColor;
    }

    .hasImage.dark & {
      background-color: $color-dark;
    }

    .hasImage.white & {
      background-color: #fff;
    }
  }

  &__background {
    .brand &,
    .dark &,
    .white & {
      margin-top: -2rem;
      padding: 10rem 0;
    }

    .underline.brand &,
    .underline.dark &,
    .underline.white & {
      position: relative;
      z-index: 1;
      margin-top: -13rem;
    }

    .hasImage:not(.underline).brand &,
    .hasImage:not(.underline).dark &,
    .hasImage:not(.underline).white & {
      position: relative;
      z-index: 1;
      margin-top: -10rem;
    }

    .brand:not(.hasImage) & {
      @include brandColor;

      :global(body.brand) & {
        background-color: transparent;
      }
    }

    .dark:not(.hasImage) & {
      background-color: $color-dark;

      :global(body.dark) & {
        background-color: transparent;
      }
    }

    .white:not(.hasImage) & {
      background-color: #fff;

      :global(body.white) & {
        background-color: transparent;
      }
    }
  }

  &__container {
    @include container();
  }

  &__list {
    counter-reset: column-count;
    margin-bottom: -3rem;

    @media (min-width: $min-960) {
      margin-bottom: -4rem;
    }

    &:not(:first-child) {
      margin-top: 5rem;

      @media (min-width: $min-720) {
        margin-top: 10rem;
      }
    }

    @include supports-grid() {
      display: grid;
      width: 100%;
      grid-row-gap: $gutter * 1.5;

      @media (min-width: $min-960) {
        grid-auto-flow: column;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-auto-columns: minmax(0, 1fr);
        grid-column-gap: $gutter * 1.5;
      }

      @media (min-width: $min-1080) {
        grid-column-gap: $gutter;
      }
    }

    @include no-supports-grid() {
      @include grid-row();

      & > * {
        @include grid-col(4);
      }
    }

    // &:not(.three) {
    //   @media (min-width: $min-1080) {
    //     padding-left: percentage(1/12);
    //   }
    // }

    // &.three {
    //   @media (min-width: $min-1280) {
    //     padding-left: percentage(1/12);
    //   }
    // }
  }
}

.item {
  height: 100%;
  max-width: 61rem;
  display: grid;
  column-gap: $gutter;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'decor' 'content';
  counter-increment: column-count;
  margin-bottom: 3rem;

  @media (min-width: $min-960) {
    margin-bottom: 4rem;
  }

  @media (min-width: $min-1280) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-areas: 'decor decor decor decor decor decor' '. content content content content content';
    max-width: none;
  }

  .three & {
    @media (min-width: $min-1280) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-areas: 'decor decor decor decor' '. content content content';
    }
  }

  &__decor {
    grid-area: decor;

    svg {
      margin-bottom: 1rem;
      width: 6rem;

      // path:last-child {
      //   @include brandColor(fill);
      // }

      :global(.st1),
      :global(.st2) {
        .white &,
        .dark & {
          @include brandColor(fill);
        }
        .brand & {
          fill: $color-dark;
        }
      }

      :global(.st0),
      :global(.st3) {
        .white &,
        .brand & {
          fill: $color-dark;
        }
        .dark & {
          fill: #fff;
        }
      }
    }
  }

  &__counter {
    display: flex;

    &::before {
      content: counter(column-count, decimal-leading-zero);
      flex: 0 0 auto;
      margin-bottom: 1.8em;
      order: -1;
      font-size: 1.2rem;
      line-height: 2.4rem;
      font-weight: bold;
      color: $color-dark;

      :global(body.brand) & {
        color: $color-dark;
      }

      .dark & {
        color: #fff;
      }

      .dark & {
        :global(body.white) &,
        :global(body.brand) & {
          color: #fff;
        }
      }

      .brand & {
        :global(body.white) &,
        :global(body.dark) & {
          color: $color-dark;
        }
      }

      .white & {
        :global(body.brand) &,
        :global(body.dark) & {
          color: $color-dark;
        }
      }
    }

    &::after {
      content: '';
      @include brandColor(background-color);
      flex: 0 0 auto;
      order: -1;
      line-height: 2.4rem;
      margin-top: 1.2rem;
      margin-left: math.div(22, 18) * 1em;
      width: math.div(37, 18) * 1em;
      height: 0.2rem;

      :global(body.brand) & {
        @include brandColorOverride(background-color, $color-dark);
      }

      :global(body.dark) & {
        @include brandColorOverride(background-color, #fff);
      }

      .dark & {
        :global(body.white) &,
        :global(body.brand) & {
          @include brandColorOverride(background-color, #fff);
        }
      }

      .brand & {
        :global(body.white) &,
        :global(body.dark) & {
          @include brandColorOverride(background-color, $color-dark);
        }
      }

      .white & {
        :global(body.brand) &,
        :global(body.dark) & {
          @include brandColor(background-color);
        }
      }
    }
  }

  &__layout {
    grid-area: content;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__content {
    flex: 1 1 auto;
  }

  &__title {
    @include h5(1.8rem, 2rem);
    font-weight: 600;
    line-height: math.div(28, 20);
    margin-bottom: 1.2em;

    @media (min-width: $min-960) {
      margin-bottom: 1.5em;
    }
  }

  &__copy {
    @include render-copy(1.5rem, 1.8rem);

    .brand & {
      p {
        color: $color-font;
      }
    }

    .dark & {
      :global(body.brand) & {
        p {
          color: $color-copy;
        }
      }
    }
  }

  &__link {
    flex: 0 1 auto;
    margin-top: 3rem;

    @media (min-width: $min-960) {
      margin-top: 4rem;
    }

    .three & {
      @media (min-width: $min-1280) {
        // margin-left: -4.1rem;
      }
    }
  }
}

.icons {
  @include segment(0, 0);

  &__background {
    @include segment-top(7rem);
    @include segment-bottom(7rem);

    .dark & {
      background-color: $color-dark;
    }

    .brand & {
      @include brandColor(background-color);
    }

    .white & {
      background-color: #fff;
    }
  }

  &__container {
    @include container;
  }

  &__list {
    @include supports-grid {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 3rem;
      row-gap: 5rem;

      @media (min-width: $min-480) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      @media (min-width: $min-960) {
        grid-template-columns: repeat(7, minmax(0, 1fr));
      }
    }

    @include no-supports-grid {
      @include grid-row;

      > * {
        @include grid-col(2);
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      margin-bottom: 1rem;
      width: 6rem;

      :global(.st1),
      :global(.st2) {
        .white &,
        .dark & {
          @include brandColor(fill);
        }
        .brand & {
          fill: $color-dark;
        }
      }

      :global(.st0),
      :global(.st3) {
        .white &,
        .brand & {
          fill: $color-dark;
        }
        .dark & {
          fill: #fff;
        }
      }
    }
  }

  &__name {
    font-size: 1.2rem;
    text-align: center;

    .dark & {
      color: $color-copy;
    }
  }
}
