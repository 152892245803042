@use 'styles/config' as *;
@use "sass:math";

.catalog {
  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__header {
    margin-bottom: 4rem;

    @media (min-width: $min-720) {
      display: flex;
      margin-bottom: 8rem;
    }
  }

  &__text {
    @media (min-width: $min-720) {
      flex: 0 0 50%;
      // max-width: 65.5rem;
      padding-right: 5rem;
    }
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h1;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__opFade {
    flex: 0 0 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    > div {
      width: 100%;
      display: flex;
      align-items: flex-end;
    }
  }

  &__options {
    // flex: 0 0 50%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 1rem;

    @media (min-width: $min-720) {
      margin-top: 0;
      margin-bottom: 0;
      justify-content: flex-end;
    }

    &List {
      @include reset-list();
      display: none;
      // width: 0;
      // opacity: 0;
      margin-left: -1rem;
      margin-right: -1rem;
      transition: opacity 200ms;

      &.visible {
        display: flex;
        // width: auto;
        // opacity: 1;
      }

      @media (min-width: $min-375) {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }

      @media (min-width: $min-960) {
        margin-left: -3rem;
        margin-right: -3rem;
      }

      @media (min-width: $min-1440) {
        display: flex;
      }
    }

    &Item {
      margin-left: 1rem;
      margin-right: 1rem;

      @media (min-width: $min-375) {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }

      @media (min-width: $min-960) {
        margin-left: 3rem;
        margin-right: 3rem;
      }
    }

    &Button {
      @include reset-button();
      position: relative;
      font-size: responsive(1.2rem, 1.4rem);
      font-weight: 700;
      opacity: 0.7;
      text-transform: uppercase;
      color: #3a3b39;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1.5rem;
        height: 0.2rem;
        background-color: transparent;
        pointer-events: none;
      }

      &.active,
      &:hover,
      &:focus {
        opacity: 1;
        color: $color-font;

        &::after {
          @include brandColor(background-color);
        }
      }
    }
  }

  &__dropdown {
    > * {
      margin-bottom: -1.5rem;
    }
  }

  &__search {
    $search: &;
    position: relative;
    display: flex;
    align-items: center;
    height: 2.7rem;
    margin-left: 2rem - 1.15rem;
    margin-right: -1.15rem;

    &::after {
      content: '';
      position: absolute;
      left: 1.15rem;
      right: 1.15rem;
      bottom: -1.2rem;
      height: 0.2rem;
      opacity: 0;
      background-color: transparent;
    }

    &.open {
      color: $color-font;
      transition: opacity 300ms;

      &::after {
        opacity: 1;
        background-color: brand();
      }
    }

    @media (min-width: $min-375) {
      margin-left: 3rem - 1.15rem;
    }

    @media (min-width: $min-960) {
      margin-left: 6rem - 1.15rem;
    }

    &Button {
      @include reset-button();
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4.4rem;
      height: 4.4rem;
      opacity: 0.7;

      cursor: pointer;

      #{ $search }.open & {
        opacity: 1;
      }
    }

    &Wrap {
      opacity: 0;
      width: 0;
      overflow: hidden;
      transition: width 300ms, opacity 300ms;

      #{ $search }.open & {
        opacity: 1;
        width: 16rem;
      }
    }

    &Input {
      @include reset-input();
      font-size: responsive(1.2rem, 1.4rem);
      font-weight: 700;
      margin-left: 1rem;
      margin-right: 1.2rem;
      max-width: 20rem;
    }

    svg {
      display: block;
      width: 1.6rem;
      height: 1.6rem;

      @media (min-width: $min-375) {
        width: 1.7rem;
        height: 1.7rem;
      }

      @media (min-width: $min-720) {
        width: 1.9rem;
        height: 1.9rem;
      }
    }
  }

  &__filter {
    margin-bottom: 6rem;

    @media (min-width: $min-720) {
      margin-bottom: 10rem;
    }

    &List {
      @include reset-list();

      @include supports-grid {
        display: grid;
        grid-gap: 0.1rem;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        overflow: hidden;

        box-shadow: 0 3rem 2rem 0 rgba(#000, 0.05), 0 0 1rem 0 rgba(#000, 0.025);

        @media (min-width: $min-420) {
          grid-template-columns: repeat(7, minmax(0, 1fr));
        }

        @media (min-width: $min-560) {
          grid-template-columns: repeat(9, minmax(0, 1fr));
        }

        @media (min-width: $min-720) {
          grid-template-columns: repeat(13, minmax(0, 1fr));
        }

        .korean & {
          @media (min-width: $min-560) {
            grid-template-columns: repeat(7, minmax(0, 1fr));
          }

          @media (min-width: $min-720) {
            grid-template-columns: repeat(14, minmax(0, 1fr));
          }
        }
      }

      @include no-supports-grid {
        display: flex;
        flex-wrap: wrap;

        > * {
          width: math.div(100%, 13);
        }
      }
    }

    &Item {
      position: relative;
      box-shadow: 0 0 0 1px $color-border;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
      }

      > * {
        position: absolute 0;
      }
    }

    &Button {
      @include reset-button();
      position: absolute 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      font-size: responsive(1.2rem, 1.4rem);
      font-weight: 700;
      text-transform: uppercase;

      .korean & {
        font-size: responsive(1.4rem, 1.6rem);
      }

      &.active {
        background: $color-border;
      }

      &:is(:hover, :focus-visible) {
        color: brandFont();
        background-color: brand();
      }
    }
  }

  &__list {
    margin-left: -$container-gutter-mobile;
    margin-right: -$container-gutter-mobile;
    box-shadow: 0 1px 0 0 $color-border;

    @media (min-width: $min-720) {
      margin-left: 0;
      margin-right: 0;
    }

    &Header {
      @include supports-grid {
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: $gutter;
        // grid-row-gap: 1px;
        grid-template-columns: 1fr;

        padding: 0 3rem;
        margin-bottom: 2rem;

        @media (min-width: $min-540) {
          padding: 0 2.5rem;
          margin-bottom: 3rem;
        }

        @media (min-width: $min-720) {
          padding: 0 2.5rem;
          margin-bottom: 4rem;
        }

        @media (min-width: $min-1200) {
          grid-template-columns: 1.4fr 1fr 5rem;
        }
      }

      @include no-supports-grid {
        display: flex;
        flex: 1 1 auto;

        > *:first-child {
          flex: 0 0 56%;
          margin-left: 2.5rem;
        }
      }
    }

    &Label {
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: math.div(0.5, 12) * 1em;
      color: $color-font-light;

      &:nth-child(2) {
        display: none;

        @media (min-width: $min-1200) {
          display: block;
        }
      }
    }
  }
}

.product {
  &__header {
    @include reset-button;
    display: block;
    width: 100%;
    text-align: left;

    @include supports-grid {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: $gutter;
      grid-row-gap: 1px;
      // grid-row-gap: 1px;
      grid-template-columns: 1fr 5rem;
      // grid-template-rows: minmax(0, auto);
      align-items: center;
      align-content: center;

      cursor: pointer;

      padding: 1rem 1.2rem 1rem 3rem;
      min-height: 7.4rem;

      box-shadow: 0 -1px 0 0 $color-border;

      @media (min-width: $min-720) {
        padding: 1rem 0 1rem 2.5rem;
      }

      @media (min-width: $min-1200) {
        padding: 1rem 2.5rem;
      }
    }

    @include no-supports-grid {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      padding: 2rem 2.5rem;
      box-shadow: 0 -1px 0 0 $color-border;
      cursor: pointer;

      > *:first-child {
        flex: 1 1 auto;
      }

      > *:last-child {
        flex: 0 0 5rem;
      }
    }
  }

  &__text {
    @media (min-width: $min-1200) {
      display: flex;
      gap: 3rem;
      justify-items: center;

      &:first-child {
        text-align: left;
      }
    }
  }

  &__title {
    margin: 0.5rem 0;
    height: 100%;
    font-size: responsive(1.3rem, 1.7rem);
    font-weight: 600;

    @media (min-width: $min-1200) {
      flex: 0 1 60%;
    }
  }

  &__warning {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin: 0.5rem 0;
    font-size: responsive(1.2rem, 1.6rem);
    font-weight: 400;
    letter-spacing: math.div(0.2, 12) * 1em;
    color: $color-font-light;

    @media (min-width: $min-1200) {
      flex: 0 1 30%;
    }
  }

  &__rems {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin: 0.5rem 0;
    font-size: responsive(1.2rem, 1.6rem);
    font-weight: 400;
    letter-spacing: math.div(0.2, 12) * 1em;
    color: $color-font-light;

    @media (min-width: $min-1200) {
      flex: 0 1 10%;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__toggle {
    @include reset-button;
    position: relative;
    height: 4.4rem;
    width: 4.4rem;

    cursor: pointer;

    transform: rotate(90deg);

    .active & {
      transform: rotate(-90deg);
    }

    svg {
      position: absolute 0;
      margin: auto;
      width: 0.88rem;
      height: 1.44rem;

      @media (min-width: $min-840) {
        width: 1.1rem;
        height: 1.8rem;
      }
    }
  }

  &__content {
    grid-column: 1 / -1;
    overflow: hidden;
    height: 0;
    // height: auto !important;
    background-color: #fafafa;
    box-shadow: 0 -1px 0 0 $color-border;
  }

  &__body {
    padding: 3rem 3rem 0;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    column-gap: 3rem;
    row-gap: 2rem;
    align-items: flex-end;

    box-shadow: 0 -1px 0 0 $color-border;
    padding: 3rem 0;
    margin: 3rem 0 0;

    @media (min-width: $min-720) {
      flex-direction: row;
      gap: 3rem;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__external {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-end;
    column-gap: 3rem;
    row-gap: 1.5rem;

    @media (min-width: $min-720) {
    }
  }

  &__rems {
    &Long {
      display: none;

      @media (min-width: $min-420) {
        display: block;
      }
    }

    &Short {
      @media (min-width: $min-420) {
        display: none;
      }
    }
  }

  &__details {
    flex: 0 0 auto;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  @media (max-width: $max-840) {
    display: block;
  }

  &__head {
    @media (max-width: $max-840) {
      display: none;
    }
  }

  &__header {
    padding: 0 1rem 3rem;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: math.div(0.5, 12) * 1em;
    color: $color-font-light;
    text-align: left;

    white-space: nowrap;

    @media (min-width: $min-840) {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__body {
    @media (max-width: $max-840) {
      display: block;
    }
  }
}

.variant {
  @media (max-width: $max-840) {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(4, auto);
    width: 100%;

    &:not(:last-child) {
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid $color-border;
    }
  }

  @media (max-width: $max-540) {
    grid-template-columns: repeat(3, auto);
  }

  &__cell {
    padding: 1rem;
    text-align: left;
    vertical-align: middle;
    font-size: responsive(1.3rem, 1.7rem);
    font-weight: 600;

    @media (min-width: $min-840) {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    @media (max-width: $max-840) {
      padding: 0;

      &:first-child {
        grid-row: 1 / 3;
      }

      &::before {
        content: attr(data-label);
        display: block;
        margin-right: 1rem;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: math.div(0.5, 12) * 1em;
        color: $color-font-light;
      }
    }

    @media (max-width: $max-540) {
      &:first-child {
        grid-row: 1 / 4;
      }
    }
  }

  &__image {
    display: flex;
    align-items: center;

    max-width: 9rem;
    min-height: 6rem;

    @media (max-width: $max-540) {
      max-width: 8rem;
    }
  }

  &__img {
    // max-width: 9rem;
    // min-height: 6rem;

    // @media (max-width: $max-540) {
    //   max-width: 8rem;
    // }
  }
}

.warning {
  position: fixed 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(#fff, 0.5);

  &__title {
    @include h3;
    margin-bottom: 1.5rem;
  }

  &__text {
    @include render-copy;
  }

  &__footer {
    margin-top: 2rem;
  }

  &__box {
    position: relative;
    padding: 4rem;
    margin: 3rem;
    background: #fff;
    max-width: 80rem;
    box-shadow: 0 0 1rem 0 rgba(#000, 0.02), 0 6rem 6rem 0 rgba(#000, 0.1);

    @media (min-width: $min-720) {
      padding: 8rem;
    }
  }

  &__close {
    @include reset-button;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 4.4rem;
    height: 4.4rem;
    cursor: pointer;

    @media (min-width: $min-720) {
      top: 2rem;
      right: 2rem;
    }
  }

  &__cross {
    position: absolute;

    margin-left: -1px;

    width: 2px;
    height: 20px;

    background: #000;

    transform: rotate(45deg);

    &:first-child {
      transform: rotate(-45deg);
    }
  }
}

.dropdown {
  position: relative;
  font-size: responsive(1.4rem, 1.6rem);
  font-weight: 600;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    margin: auto;

    height: 0;
    width: 0;

    border: 0.5rem solid transparent;
    border-top-width: 0.6rem;
    border-top-color: $color-dark;

    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1.5rem;
    height: 0.1rem;
    background-color: $color-border-form;
    opacity: 1;
    pointer-events: none;
  }

  &:hover::before {
    height: 0.2rem;
    background-color: brand();
  }

  select {
    @include reset-input;
    letter-spacing: 0.03em;
    padding-right: 2.5ch;
    // text-transform: uppercase;
  }

  select::-ms-expand {
    display: none;
  }
}
